<client-layout-header class="z-3" />
<div class="main d-grid flex-grow-1 overflow-hidden h-min-0">
  <client-layout-side-nav class="shadow-3 bg-color-white z-2" />
  <div class="z-1 d-flex flex-column justify-content-stretch align-items-stretch overflow-hidden h-min-0 overflow-y-auto">
    <router-outlet />
  </div>
</div>

<app-batch-actions-bar class="z-3"></app-batch-actions-bar>

<dialog-modal
  class="no-dividers"
  icon="error"
  [title]="errorTitle"
  [content]="errorContent"
  [buttons]="errorButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', name: 'error-default', showClose: true }"
  #errorPopup
>
</dialog-modal>
<ng-template #errorTitle let-title="title">
  {{ title || 'Error!' }}
</ng-template>
<ng-template #errorContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #errorButtons>
  <button type="button" mat-raised-button (click)="errorPopup.hide()">Close</button>
</ng-template>

<dialog-modal
  icon="warning"
  class="no-dividers"
  [title]="warningTitle"
  [content]="warningContent"
  [buttons]="warningButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', showClose: true, name: 'warning-default' }"
  #warningPopup
></dialog-modal>
<ng-template #warningTitle let-title="title">
  {{ title || 'Warning!' }}
</ng-template>
<ng-template #warningContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #warningButtons>
  <button type="button" mat-raised-button (click)="warningPopup.hide()">Close</button>
</ng-template>

<dialog-modal
  class="no-dividers"
  titleAlign="left"
  [title]="confirmTitle"
  [content]="confirmContent"
  [buttons]="confirmButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', showClose: true, name: 'confirm-default' }"
  #confirmPopup
></dialog-modal>
<ng-template #confirmTitle let-title="title">
  {{ title || 'Confirm!' }}
</ng-template>
<ng-template #confirmContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #confirmButtons let-cancelText="cancelText" let-confirmText="confirmText" let-confirmCb="confirmCb">
  <button type="button" mat-stroked-button color="outline" (click)="confirmPopup.hide()">
    {{ cancelText || 'Cancel' }}
  </button>
  <button type="button" mat-raised-button class="ms-auto" (click)="confirmCb(confirmPopup)">
    {{ confirmText || 'Confirm' }}
  </button>
</ng-template>

<dialog-modal
  icon="success"
  class="no-dividers"
  [title]="successTitle"
  [content]="successContent"
  [buttons]="successButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', showClose: true, name: 'success-default' }"
  #successPopup
></dialog-modal>
<ng-template #successTitle let-title="title">
  <span *ngIf="title">{{ title }}</span>
  <span *ngIf="!title">Success!</span>
</ng-template>
<ng-template #successContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #successButtons>
  <button type="button" mat-raised-button (click)="successPopup.hide()">Close</button>
</ng-template>

<dialog-modal
  icon="success"
  class="no-dividers"
  [title]="success1CallbackTitle"
  [content]="success1CallbackContent"
  [buttons]="success1CallbackButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', showClose: true, name: 'success-one-callback-default' }"
  #success1CallbackPopup
></dialog-modal>
<ng-template #success1CallbackTitle let-title="title">
  {{ title || 'Success!' }}
</ng-template>
<ng-template #success1CallbackContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template #success1CallbackButtons let-buttonText="buttonText" let-buttonCb="buttonCb">
  <button type="button" mat-raised-button (click)="buttonCb(success1CallbackPopup)">
    {{ buttonText || 'Confirm' }}
  </button>
</ng-template>

<dialog-modal
  icon="success"
  class="no-dividers"
  [title]="success2CallbacksTitle"
  [content]="success2CallbacksContent"
  [buttons]="success2CallbacksButtons"
  [config]="{ hasBackdrop: true, backdropClass: 'is-transparent', showClose: true, name: 'success-two-callbacks-default' }"
  #success2CallbacksPopup
></dialog-modal>
<ng-template #success2CallbacksTitle let-title="title">
  {{ title || 'Success!' }}
</ng-template>
<ng-template #success2CallbacksContent let-message="message">
  <div [innerHTML]="message"></div>
</ng-template>
<ng-template
  #success2CallbacksButtons
  let-button1Text="button1Text"
  let-button1Cb="button1Cb"
  let-button2Text="button2Text"
  let-button2Cb="button2Cb"
>
  <button type="button" mat-stroked-button color="primary" (click)="button1Cb(success2CallbacksPopup)">
    {{ button1Text || 'Confirm' }}
  </button>
  <button type="button" mat-raised-button class="ms-auto" (click)="button2Cb(success2CallbacksPopup)">
    {{ button2Text || 'Confirm' }}
  </button>
</ng-template>

<app-disabled-accounts-popup />
